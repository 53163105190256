import styled from "styled-components"

export const Seperator = styled.hr`
  margin: 40px auto;
  width: 30px;
  height: 2px;
  background-color: #fff;
`

export const Wrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
`
