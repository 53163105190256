import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import DefaultLayout from "../layouts/DefaultLayout"
import { Wrapper } from "../components/Layout"
import { Icon } from "antd"

const Container = styled.div`
  padding: 20px;
  height: 100vh;
  text-align: center;
  display: flex;
  align-items: center;
  h1 {
    line-height: 1.25;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  span {
    margin-left: 10px;
  }
  p {
    margin-bottom: 30px;
  }
`

const NotFoundPage = () => (
  <DefaultLayout>
    <Container>
      <Wrapper>
        <h1 className="h1">Fant ikke siden</h1>
        <p className="p">Beklager, men det ser dessverre ikke ut som vi fant siden du prøvde å nå</p>
        <Link to="/">
          <Icon type="left-circle" />
          <span>Gå tilbake</span>
        </Link>
      </Wrapper>
    </Container>
  </DefaultLayout>
)

export default NotFoundPage
